// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-account-index-js": () => import("./../../../src/pages/account/index.js" /* webpackChunkName: "component---src-pages-account-index-js" */),
  "component---src-pages-account-update-password-js": () => import("./../../../src/pages/account/update_password.js" /* webpackChunkName: "component---src-pages-account-update-password-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-expert-signup-index-js": () => import("./../../../src/pages/expert-signup/index.js" /* webpackChunkName: "component---src-pages-expert-signup-index-js" */),
  "component---src-pages-faqs-index-js": () => import("./../../../src/pages/faqs/index.js" /* webpackChunkName: "component---src-pages-faqs-index-js" */),
  "component---src-pages-fertile-edge-magazine-index-js": () => import("./../../../src/pages/fertile-edge-magazine/index.js" /* webpackChunkName: "component---src-pages-fertile-edge-magazine-index-js" */),
  "component---src-pages-how-it-works-index-js": () => import("./../../../src/pages/how-it-works/index.js" /* webpackChunkName: "component---src-pages-how-it-works-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-index-js": () => import("./../../../src/pages/login/index.js" /* webpackChunkName: "component---src-pages-login-index-js" */),
  "component---src-pages-login-recover-js": () => import("./../../../src/pages/login/recover.js" /* webpackChunkName: "component---src-pages-login-recover-js" */),
  "component---src-pages-med-answers-index-js": () => import("./../../../src/pages/MedAnswers/index.js" /* webpackChunkName: "component---src-pages-med-answers-index-js" */),
  "component---src-pages-partners-index-js": () => import("./../../../src/pages/partners/index.js" /* webpackChunkName: "component---src-pages-partners-index-js" */),
  "component---src-pages-partners-member-js": () => import("./../../../src/pages/partners/member.js" /* webpackChunkName: "component---src-pages-partners-member-js" */),
  "component---src-pages-partners-profile-js": () => import("./../../../src/pages/partners/profile.js" /* webpackChunkName: "component---src-pages-partners-profile-js" */),
  "component---src-pages-privacy-index-js": () => import("./../../../src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */),
  "component---src-pages-questions-detail-js": () => import("./../../../src/pages/questions/detail.js" /* webpackChunkName: "component---src-pages-questions-detail-js" */),
  "component---src-pages-questions-index-js": () => import("./../../../src/pages/questions/index.js" /* webpackChunkName: "component---src-pages-questions-index-js" */),
  "component---src-pages-questions-search-js": () => import("./../../../src/pages/questions/search.js" /* webpackChunkName: "component---src-pages-questions-search-js" */),
  "component---src-pages-questions-share-js": () => import("./../../../src/pages/questions/share.js" /* webpackChunkName: "component---src-pages-questions-share-js" */),
  "component---src-pages-signup-confirmation-js": () => import("./../../../src/pages/signup/confirmation.js" /* webpackChunkName: "component---src-pages-signup-confirmation-js" */),
  "component---src-pages-signup-expert-create-js": () => import("./../../../src/pages/signup/expert_create.js" /* webpackChunkName: "component---src-pages-signup-expert-create-js" */),
  "component---src-pages-signup-index-js": () => import("./../../../src/pages/signup/index.js" /* webpackChunkName: "component---src-pages-signup-index-js" */),
  "component---src-pages-signup-patient-create-js": () => import("./../../../src/pages/signup/patient_create.js" /* webpackChunkName: "component---src-pages-signup-patient-create-js" */),
  "component---src-pages-terms-of-service-experts-js": () => import("./../../../src/pages/terms-of-service/experts.js" /* webpackChunkName: "component---src-pages-terms-of-service-experts-js" */),
  "component---src-pages-terms-of-service-patients-js": () => import("./../../../src/pages/terms-of-service/patients.js" /* webpackChunkName: "component---src-pages-terms-of-service-patients-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-popular-question-js": () => import("./../../../src/templates/popular-question.js" /* webpackChunkName: "component---src-templates-popular-question-js" */)
}

