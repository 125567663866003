const initialState = {
  openHambergermenu: false,
  loading: true,
  allquestions: [],

};

export const toggleMenu = (isopen) => ({
  type: "TOOGLE_MENU",
  isopen,
});

export const stroreUser = (data) => ({
  type: "STORE_USER",
  data,
});

export const addAllQuestions = (content) => ({
  type: "ADD_QUESTIONS",
  content,
});

export const watchloading = (loading) => ({
  type: "WATCH_LOADING",
  loading,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case "TOOGLE_MENU":
      return { ...state, openHambergermenu: action.isopen };

    case "WATCH_LOADING":
      return { ...state, loading: action.loading };

    case "ADD_QUESTIONS":
      return { ...state, allquestions: action.content };

    case "STORE_USER":
      return { ...state, userinfo: action.data };

    default:
      return state;
  }
};
